// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'jquery';
import 'bootstrap';

require("@rails/ujs").start()
require("turbolinks").start()
// require("channels")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', function() {
  var container = $("body");

  var map;

  function init_all_maps() {
    container.find(".gmap").each(function(){
      init_map(this);
    });
  };

  function init_map(map_elem) {
    map = new google.maps.Map(map_elem, {
      center: {
        lat: 57.5061737,
        lng: 22.797084
      },
      zoom: 14
    });

    locations.forEach(function(i){
      const infowindow = new google.maps.InfoWindow({
        content: "<b>" + i.name + "</b> " + i.address
      });

      const marker = new google.maps.Marker({
        position: {lat: i.lat, lng: i.lng},
        icon: { url: map_marker_icon, scaledSize: new google.maps.Size(33, 20)},
        map: map,
        title: i.name
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });

    });
  }
  init_all_maps();
});
